function scroll() {
  // Движение начинается, когда верхний край экрана == верхнему краю блока
  // Движение заканчивается, когда середина экрана достигла середины контейнера с картинками
  // Получить процент
  const blockContainers = document.querySelectorAll('[data-need-scroll]');
  blockContainers.forEach((blockContainer) => {
    if (blockContainer) {
      const imageContainer = blockContainer.querySelector('[data-image-container]');
      const blockRect = blockContainer.getBoundingClientRect();
      const imageRect = imageContainer.getBoundingClientRect();
      // Верхняя линия блока
      const topBorderBlockPosition = blockRect.top;
      // Верхняя линяя блока картинки
      const topBorderImagePosition = imageRect.top;
      // Середина блока с картинками
      const middleImagePosition = imageRect.height / 2 + topBorderImagePosition;
      const clientHeight = window.innerHeight;
      const screenHalfHeight = clientHeight / 2;
      // Проскроленный участок, невидимый нам
      const absTopblockPosition = Math.abs(topBorderBlockPosition);
      const images = blockContainer.querySelectorAll('[data-offset-x][data-offset-y]');
      let percent = 100;
      if (topBorderBlockPosition < 0 && middleImagePosition > screenHalfHeight) {
        const totalProgressHeight = absTopblockPosition + middleImagePosition - screenHalfHeight;
        // Формула проскроленного процента
        percent = 100 - absTopblockPosition / (totalProgressHeight / 100);
      } else if (topBorderBlockPosition < 0) {
        percent = 0;
      }
      images.forEach((item) => {
        // Получаем значение из data-атрибута по Х && У
        const dataValueX = item.dataset.offsetX;
        const dataValueY = item.dataset.offsetY;
        // Парсим строку в число
        const parsedValueX = parseInt(dataValueX, 10);
        const parsedValueY = parseInt(dataValueY, 10);
        // Присваиваем новое значение для transform( in scss )
        item.style.transform = `translate3d(${parsedValueX / 100 * percent}px, ${parsedValueY / 100 * percent}px, 0)`;
      });
    }
  });
}
if (window.screen.width >= 768) {
  scroll();
  window.addEventListener('scroll', scroll);
}