function initHamburgers() {
  const mobileHamburger = document.querySelector('[data-mobile-hamburger]');
  if (mobileHamburger) {
    mobileHamburger.addEventListener('click', (e) => {
      const menuItems = document.querySelectorAll('[data-expand-block');
      menuItems.forEach((item) => {
        if (!item.classList.contains('_opened')) {
          item.classList.add('_opened');
          mobileHamburger.classList.add('_opened');
        } else {
          item.classList.remove('_opened');
          mobileHamburger.classList.remove('_opened');
        }
      });
    });
  }
}
document.addEventListener('DOMContentLoaded', initHamburgers);