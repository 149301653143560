class Accordion {
    constructor(options) {
        this.options = {
            tabSelector: '[data-accordion-tab]',
            itemSelector: '[data-accordion-item]',
            itemWrapper: '[data-accordion-tab-wrapper]',
        };
        this.options = Object.assign({}, this.options, options);
        this.links = document.querySelectorAll(this.options.tabSelector);
        this.items = document.querySelectorAll(this.options.itemSelector);
        this.blocks = [];
        this.bind();
    }

    bind() {
        this.links.forEach((link) => {
            if (!link.dataset.handled) {
                link.addEventListener('click', this.handleClick.bind(this, link));
                link.dataset.handled = 'true';

                if (this.isOpened(link)) {
                    const parent = link.closest(this.options.itemSelector);
                    const target = link.closest(this.options.itemWrapper).nextElementSibling;

                    window.onload = () => {
                        this.openTab(parent, target);
                    };
                }
            }
        });
    }

    isOpened(link) {
        const parent = link.closest(this.options.itemSelector);
        return parent.classList.contains('_opened');
    }

    handleClick(link) {
        const parent = link.closest(this.options.itemSelector);
        const target = link.closest(this.options.itemWrapper).nextElementSibling;
        const isTargetOpened = parent.classList.contains('_opened');

        // Закрыть все opened
        this.closeAll();

        if (!isTargetOpened) {
            this.openTab(parent, target);
        }
    }

    closeAll() {
        this.links.forEach((link) => {
            if (this.isOpened(link)) {
                const parent = link.closest(this.options.itemSelector);
                const target = link.closest(this.options.itemWrapper).nextElementSibling;
                this.closeTab(parent, target);
            }
        });
    }

    closeTab(parent, target) {
        target.style.height = 0;
        parent.classList.remove('_opened');
    }

    openTab(parent, target) {
        const article = target.children[0];
        const targetHeight = article.clientHeight;
        target.style.height = `${targetHeight}px`;

        if (!parent.classList.contains('_opened')) {
            parent.classList.add('_opened');
        }

        this.openImage(parent);
    }

    openImage(parent) {
        const pictures = document.querySelectorAll('[data-accordion-image]');

        pictures.forEach((picture) => {
          if (picture.dataset.accordionImage === parent.dataset.accordionItem){
            picture.classList.add('_opened');
          } else {
            picture.classList.remove('_opened');
          }
        });
    }
}

export default Accordion;