const bindSticky = () => {
  const headerElem = document.querySelector('[data-sticky-header]');

  if (!headerElem) {
    return;
  }

  document.addEventListener('scroll', () => {
    const winScrollTop = window.pageYOffset;

    if (winScrollTop > 10) {
      headerElem.classList.add('_fixed');
    } else {
      headerElem.classList.remove('_fixed');
    }
  });
};

document.addEventListener('DOMContentLoaded', bindSticky);